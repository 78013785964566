<script>
    import PackChip from '@components/Packs/PackChip.vue'
    import FormationAttributeChip from '@components/Formations/FormationAttributeChip.vue'
    import { FormationStructure } from '@libs/Structures/FormationStructure.js'

    export default {
        name: 'FormationCard',
        components: { FormationAttributeChip, PackChip },
        emits: ['click:edit', 'click:delete'],
        props: {
            formation: {
                type: FormationStructure,
                default: () => {},
            },
            canManage: {
                default: () => false,
            },
        },
    }
</script>

<template>
    <div>
        <web>
            <div class="formation-card-hover">
                <ra-box
                    :style="{
                        '--leftColor': formation.pack?.left_color,
                        '--rightColor': formation.pack?.right_color,
                    }"
                    bg="white"
                    class="formation-card"
                    rounded
                    v-bind="$attrs"
                >
                    <pack-chip
                        :pack="formation.pack"
                        height="15px"
                        padding="3,"
                    />
                    <ra-box
                        v-if="
                            typeof canManage == 'function' &&
                            canManage(formation)
                        "
                        bg="white"
                        class="formation-management-actions"
                        padding="1,3"
                        @click.stop
                    >
                        <ra-stack direction="horizontal">
                            <v-btn
                                density="compact"
                                icon
                                size="small"
                                variant="plain"
                            >
                                <ra-icon
                                    weight="700"
                                    @click="$emit('click:edit')"
                                    >fa-edit</ra-icon
                                >
                            </v-btn>
                            <v-btn
                                density="compact"
                                icon
                                size="small"
                                variant="plain"
                            >
                                <ra-icon
                                    weight="700"
                                    @click="$emit('click:delete')"
                                    data-test="delete-formation"
                                    >fa-trash</ra-icon
                                >
                            </v-btn>
                        </ra-stack>
                    </ra-box>
                    <ra-box height="180px">
                        <img
                            :src="formation?.image?.url"
                            alt="formation-image"
                            class="formation-card-image"
                        />
                    </ra-box>
                    <ra-stack
                        gap="4"
                        padding="4"
                    >
                        <ra-typo
                            bold
                            h2
                            variant="title"
                            >{{ formation.name }}</ra-typo
                        >
                        <ra-stack direction="horizontal">
                            <formation-attribute-chip
                                v-if="
                                    formation?.modules_count ||
                                    formation?.modules?.length
                                "
                                :icon="formation.icon"
                                bg="surface-darken-1"
                            >
                                <ra-typo
                                    bold
                                    span
                                    uppercase
                                >
                                    {{
                                        formation?.modules_count ||
                                        formation?.modules?.length
                                    }}
                                </ra-typo>
                                <ra-typo
                                    span
                                    uppercase
                                >
                                    {{
                                        $tc(
                                            'formations.modules._name',
                                            formation?.modules_count ||
                                                formation?.modules?.length
                                        )
                                    }}
                                </ra-typo>
                            </formation-attribute-chip>
                            <formation-attribute-chip
                                v-if="formation.is_custom"
                                :icon="formation.icon"
                                bg="surface-darken-1"
                            >
                                <ra-typo
                                    span
                                    uppercase
                                >
                                    {{ formation.content_type }}
                                </ra-typo>
                            </formation-attribute-chip>
                            <formation-attribute-chip
                                bg="surface-darken-1"
                                icon="fa-clock"
                            >
                                <ra-typo
                                    bold
                                    span
                                    uppercase
                                >
                                    {{ formation.nb_minutes }}
                                </ra-typo>
                                <ra-typo
                                    span
                                    uppercase
                                >
                                    min
                                </ra-typo>
                            </formation-attribute-chip>
                        </ra-stack>
                        <ra-typo
                            v-if="!formation.lastSession"
                            class="formation-description"
                        >
                            {{ formation.description }}
                        </ra-typo>
                        <ra-stack
                            v-else
                            gap="0"
                        >
                            <ra-stack
                                direction="horizontal"
                                gap="2"
                            >
                                <ra-icon>fa-stopwatch</ra-icon>
                                <ra-typo
                                    semi
                                    uppercase
                                    variant="small"
                                >
                                    {{ $t('formations_sessions.progression') }}
                                </ra-typo>
                            </ra-stack>
                            <ra-stack
                                alignement="center"
                                direction="horizontal"
                            >
                                <ra-box flex="1 1 auto">
                                    <v-progress-linear
                                        :model-value="
                                            formation.lastSession.progress
                                        "
                                        height="6"
                                        rounded
                                    />
                                </ra-box>
                                <ra-typo
                                    flex="0 0 auto"
                                    semi
                                >
                                    {{ formation.lastSession.progress }}
                                    %</ra-typo
                                >
                            </ra-stack>
                        </ra-stack>
                    </ra-stack>
                </ra-box>
            </div>
        </web>
        <!-- Tablet -->
        <tablet>
            <ra-box
                bg="surface-darken-1"
                class="formation-card"
                rounded
                v-bind="$attrs"
            >
                <pack-chip
                    :pack="formation.pack"
                    height="15px"
                    padding="3,"
                />
                <ra-box height="140px">
                    <img
                        :src="formation?.image?.url"
                        alt="formation-image"
                    />
                </ra-box>
                <ra-box padding="4,">
                    <ra-typo
                        center
                        semi
                        uppercase
                        variant="subtitle"
                    >
                        {{ formation.name }}
                    </ra-typo>
                </ra-box>
            </ra-box>
        </tablet>
    </div>
</template>

<style lang="scss">
    .formation-card {
        pointer-events: all;
        cursor: pointer;
        h2 {
            font-size: 23px !important;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .formation-card-hover {
        pointer-events: none;
        max-width: 350px;
        position: relative;
        margin: 10px;
        &:hover {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: scale(1.05);
                border-radius: 15px;
                border: 5px solid rgba(9, 9, 84, 0.667);
                background: rgba(9, 9, 84, 0.09);
                z-index: 20;
            }
        }
    }

    [data-device='web'] .formation-card {
        position: relative;
        padding-bottom: 1rem;
        height: 100%;

        &:after {
            background-image: linear-gradient(
                to right,
                var(--leftColor),
                var(--rightColor)
            );
            content: '';
            display: block;
            height: 1rem;
            width: 100%;
            position: absolute;
            bottom: 0;
        }

        .pack-chip {
            position: absolute;
            padding: 0 1em;
            border-radius: 0 0 8px 0;
            width: auto;
            height: 45px;
        }

        .formation-management-actions {
            position: absolute;
            right: 0;
            border-radius: 0 0 0 8px;
            top: 0;
        }

        .formation-description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            -webkit-box-orient: vertical;
        }
    }

    [data-device='tablet'] .formation-card {
        max-width: 300px;
        width: 100%;
        height: 100%;
        margin: auto;
        overflow: hidden;

        .pack-chip {
            width: 100%;
            height: 40px;
        }
        .formation-management-actions {
            display: none;
        }
    }
</style>
